import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-3", attrs: { align: "left", justify: "center" } },
    [
      _c("Loading", {
        attrs: { active: _vm.loadingInicial, "is-full-page": true }
      }),
      _c(
        VCard,
        { attrs: { align: "left", justify: "left" } },
        [
          _c(
            VCardText,
            { staticClass: "pa-3 font-weight-light white black--text" },
            [
              _c(VIcon, { staticClass: "primary--text lighten-2 pa-2" }, [
                _vm._v(" mdi-chart-box ")
              ]),
              _vm._v("Relatórios > Ofertas > Ofertas Globais ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCard,
        {},
        [
          _c(
            VRow,
            { staticClass: "mx-3 mt-3 py-4", attrs: { align: "center" } },
            [
              _c(
                VCol,
                { staticClass: "px-1 py-3", attrs: { lg: "12" } },
                [
                  _c(
                    VCardText,
                    { staticClass: "pa-0" },
                    [
                      _c(VIcon, { staticClass: "mx-2" }, [
                        _vm._v("mdi-information-outline")
                      ]),
                      _vm._v(
                        "Utilize os campos abaixo para aplicar filtros na pesquisa, após selecionar clique em Exportar Resultados"
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(VTextField, {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Id da Oferta",
                      placeholder: "Id da Oferta",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.oferta_id,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "oferta_id", $$v)
                      },
                      expression: "filtros.oferta_id"
                    }
                  })
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(VSelect, {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      items: _vm.status_oferta_items,
                      "item-text": "label",
                      "item-value": "value",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Status da Oferta",
                      placeholder: "Status da Oferta",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.status_oferta,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "status_oferta", $$v)
                      },
                      expression: "filtros.status_oferta"
                    }
                  })
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(VSelect, {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      items: _vm.status_renegociacao_items,
                      "item-text": "label",
                      "item-value": "value",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Status da Renegociação",
                      placeholder: "Status da Renegociação",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.status_renegociacao,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "status_renegociacao", $$v)
                      },
                      expression: "filtros.status_renegociacao"
                    }
                  })
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(VSelect, {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      items: _vm.status_pagamento_items,
                      "item-text": "label",
                      "item-value": "value",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Status do Pagamento",
                      placeholder: "Status do Pagamento",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.status_pagamento,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "status_pagamento", $$v)
                      },
                      expression: "filtros.status_pagamento"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            { staticClass: "mx-3 mt-3 py-4", attrs: { align: "center" } },
            [
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(
                    VMenu,
                    {
                      attrs: {
                        solo: "",
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  {
                                    attrs: {
                                      clearable: "",
                                      solo: "",
                                      "hide-details": "auto",
                                      value: _vm.filtros.permanencia_inicio,
                                      label: "Data de permanência Inicio",
                                      "prepend-icon": "mdi-calendar-cursor",
                                      "persistent-hint": "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:clear": function($event) {
                                        return _vm.limparFiltroData()
                                      }
                                    },
                                    model: {
                                      value: _vm.filtros.permanencia_inicio,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filtros,
                                          "permanencia_inicio",
                                          $$v
                                        )
                                      },
                                      expression: "filtros.permanencia_inicio"
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.menuDataPermanenciaInicio,
                        callback: function($$v) {
                          _vm.menuDataPermanenciaInicio = $$v
                        },
                        expression: "menuDataPermanenciaInicio"
                      }
                    },
                    [
                      _c(VDatePicker, {
                        attrs: {
                          "selected-items-text": "Datas Selecionadas",
                          color: "primary",
                          locale: "pt-br"
                        },
                        model: {
                          value: _vm.filtros.permanencia_inicio,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "permanencia_inicio", $$v)
                          },
                          expression: "filtros.permanencia_inicio"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(
                    VMenu,
                    {
                      attrs: {
                        solo: "",
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  {
                                    attrs: {
                                      clearable: "",
                                      solo: "",
                                      "hide-details": "auto",
                                      value: _vm.filtros.permanencia_fim,
                                      label: "Data de permanência Fim",
                                      "prepend-icon": "mdi-calendar-cursor",
                                      "persistent-hint": "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:clear": function($event) {
                                        return _vm.limparFiltroData()
                                      }
                                    },
                                    model: {
                                      value: _vm.filtros.permanencia_fim,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filtros,
                                          "permanencia_fim",
                                          $$v
                                        )
                                      },
                                      expression: "filtros.permanencia_fim"
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.menuDataPermanenciaFim,
                        callback: function($$v) {
                          _vm.menuDataPermanenciaFim = $$v
                        },
                        expression: "menuDataPermanenciaFim"
                      }
                    },
                    [
                      _c(VDatePicker, {
                        attrs: {
                          "selected-items-text": "Datas Selecionadas",
                          color: "primary",
                          locale: "pt-br"
                        },
                        model: {
                          value: _vm.filtros.permanencia_fim,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "permanencia_fim", $$v)
                          },
                          expression: "filtros.permanencia_fim"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(
                    VMenu,
                    {
                      attrs: {
                        solo: "",
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  {
                                    attrs: {
                                      clearable: "",
                                      solo: "",
                                      "hide-details": "auto",
                                      value: _vm.filtros.vencimento_inicio,
                                      label: "Data de vencimento inícial",
                                      "prepend-icon": "mdi-calendar-cursor",
                                      "persistent-hint": "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:clear": function($event) {
                                        return _vm.limparFiltroData()
                                      }
                                    },
                                    model: {
                                      value: _vm.filtros.vencimento_inicio,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filtros,
                                          "vencimento_inicio",
                                          $$v
                                        )
                                      },
                                      expression: "filtros.vencimento_inicio"
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.menuDataVencimentoInicio,
                        callback: function($$v) {
                          _vm.menuDataVencimentoInicio = $$v
                        },
                        expression: "menuDataVencimentoInicio"
                      }
                    },
                    [
                      _c(VDatePicker, {
                        attrs: {
                          "selected-items-text": "Datas Selecionadas",
                          color: "primary",
                          locale: "pt-br"
                        },
                        model: {
                          value: _vm.filtros.vencimento_inicio,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "vencimento_inicio", $$v)
                          },
                          expression: "filtros.vencimento_inicio"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(
                    VMenu,
                    {
                      attrs: {
                        solo: "",
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  {
                                    attrs: {
                                      clearable: "",
                                      solo: "",
                                      "hide-details": "auto",
                                      value: _vm.filtros.vencimento_fim,
                                      label: "Data de vencimento final",
                                      "prepend-icon": "mdi-calendar-cursor",
                                      "persistent-hint": "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:clear": function($event) {
                                        return _vm.limparFiltroData()
                                      }
                                    },
                                    model: {
                                      value: _vm.filtros.vencimento_fim,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filtros,
                                          "vencimento_fim",
                                          $$v
                                        )
                                      },
                                      expression: "filtros.vencimento_fim"
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.menuDataVencimentoFim,
                        callback: function($$v) {
                          _vm.menuDataVencimentoFim = $$v
                        },
                        expression: "menuDataVencimentoFim"
                      }
                    },
                    [
                      _c(VDatePicker, {
                        attrs: {
                          "selected-items-text": "Datas Selecionadas",
                          color: "primary",
                          locale: "pt-br"
                        },
                        model: {
                          value: _vm.filtros.vencimento_fim,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "vencimento_fim", $$v)
                          },
                          expression: "filtros.vencimento_fim"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            { staticClass: "mx-3 mt-3 py-4", attrs: { align: "center" } },
            [
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(VTextField, {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Porcentagem(%) de desconto inicial",
                      placeholder: "Porcentagem(%) de desconto inicial",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.desconto_porcentagem_inicial,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.filtros,
                          "desconto_porcentagem_inicial",
                          $$v
                        )
                      },
                      expression: "filtros.desconto_porcentagem_inicial"
                    }
                  })
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(VTextField, {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Porcentagem(%) de desconto final",
                      placeholder: "Porcentagem(%) de desconto final",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.desconto_porcentagem_final,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "desconto_porcentagem_final", $$v)
                      },
                      expression: "filtros.desconto_porcentagem_final"
                    }
                  })
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(VTextField, {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Valor(R$) de desconto inicial",
                      placeholder: "Valor(R$) de desconto inicial",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.desconto_valor_inicial,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "desconto_valor_inicial", $$v)
                      },
                      expression: "filtros.desconto_valor_inicial"
                    }
                  })
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(VTextField, {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Valor(R$) de desconto final",
                      placeholder: "Valor(R$) de desconto final",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.desconto_valor_final,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "desconto_valor_final", $$v)
                      },
                      expression: "filtros.desconto_valor_final"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            { staticClass: "mx-3 mt-3 py-4", attrs: { align: "center" } },
            [
              _c(
                VCol,
                { attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" } },
                [
                  _c(VSelect, {
                    attrs: {
                      clearable: "",
                      solo: "",
                      small: "",
                      items: _vm.tipo_pagamento_items,
                      "item-text": "label",
                      "item-value": "value",
                      "prepend-icon": "mdi-chevron-right",
                      label: "Tipo de Pagamento",
                      placeholder: "Tipo de Pagamento",
                      elevation: "0",
                      "hide-details": "auto"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.filtrar()
                      }
                    },
                    model: {
                      value: _vm.filtros.tipo_pagamento,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "tipo_pagamento", $$v)
                      },
                      expression: "filtros.tipo_pagamento"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            {
              staticClass: "mx-3 mt-3 py-4",
              attrs: { align: "center", justify: "end" }
            },
            [
              _c(
                VBtn,
                {
                  staticClass: "mr-3",
                  attrs: {
                    color: "primary",
                    disabled: false,
                    loading: _vm.loadingFilter
                  },
                  on: {
                    click: function($event) {
                      return _vm.filtrar()
                    }
                  }
                },
                [
                  _vm._v(" Buscar Resultados "),
                  _c(VIcon, { staticClass: "ml-2" }, [_vm._v("mdi-magnify")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCard,
        { staticClass: "mt-6" },
        [
          _c(VDataTable, {
            staticClass: "elevation-1 pa-3",
            attrs: {
              headers: _vm.headers,
              items: _vm.resultadosOfertasGlobais,
              "footer-props": {
                itemsPerPageOptions: [10, 50, 100, 500, -1]
              },
              loading: _vm.loadingTable,
              "loading-text": "Pesquisando títulos...",
              "no-data-text":
                "Nenhum resultado encontrado... Faça uma pesquisa",
              "no-results-text": "Nenhum resultado encontrado..."
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.id",
                  fn: function(ref) {
                    var item = ref.item
                    return [_c("span", [_vm._v(" " + _vm._s(item.id) + " ")])]
                  }
                },
                {
                  key: "item.valor_de",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDinheiroString(item.valor_de)) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.valor_ate",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDinheiroString(item.valor_ate)) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.data_inicio",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.formatDate(item.data_inicio)) + " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.data_fim",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.formatDate(item.data_fim)) + " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.desconto_porcentagem",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatPorcentagem(item.desconto_porcentagem)
                            ) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.desconto",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDinheiroString(item.desconto)) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.status",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(VSwitch, {
                        style: {
                          color: item.status != "" ? "green" : "red"
                        },
                        attrs: {
                          color: item.status != "" ? "green" : "red",
                          readonly: "",
                          inset: ""
                        },
                        model: {
                          value: item.status,
                          callback: function($$v) {
                            _vm.$set(item, "status", $$v)
                          },
                          expression: "item.status"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.cartao",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        VIcon,
                        {
                          style: { color: item.cartao != "" ? "green" : "red" }
                        },
                        [_vm._v(_vm._s(_vm.getBolean(item.cartao)))]
                      )
                    ]
                  }
                },
                {
                  key: "item.juros_cartao",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatPorcentagem(item.juros_cartao)) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.boleto",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        VIcon,
                        {
                          style: { color: item.boleto != "" ? "green" : "red" }
                        },
                        [_vm._v(_vm._s(_vm.getBolean(item.boleto)))]
                      )
                    ]
                  }
                },
                {
                  key: "item.juros_boleto",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatPorcentagem(item.juros_boleto)) +
                            " "
                        )
                      ])
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        VCard,
        { staticClass: "mt-3" },
        [
          _c(
            VRow,
            { staticClass: "my-4 ml-4" },
            [
              _c(
                VCol,
                { staticClass: "text-end", attrs: { lg: "12" } },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "mx-6",
                      attrs: {
                        color: "primary",
                        disabled: !_vm.titulos.length,
                        loading: _vm.loadingExport
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportar()
                        }
                      }
                    },
                    [
                      _vm._v(" Exportar resultados "),
                      _c(
                        VIcon,
                        { staticClass: "ma-2", attrs: { small: "" } },
                        [_vm._v("cloud_download")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.snackbarDialog
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbarDialog,
              corSnackbar: _vm.snackbarColor,
              mensagemSnackbar: _vm.mensagemRetornoSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbarDialog = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }